import { defineStore } from 'pinia'
export const useAppStore = defineStore('app', {
	state: () => ({
		accountData: {
			cuid: undefined,
			nickname: undefined,
			email: undefined,
			lastTestDate: undefined,
			token: undefined,
		},
		accountCreation: {
			accountCreated: false,
			currentStep: 'ACCuid',
			steps: [
				'ACCuid',
				'onboarding',
				'Inicio',
				'Nombre',
				'FechaTest',
				'Email',
				'Pin'
			],
			temporaryData: null,
		},
		isLoggedIn: false,
		userEvents: [],
		submittedQuestions: [],
		showNavBack: false,
	}),

	getters: {
	},

	actions: {
		addUserEvent(obj) {
			this.userEvents.push(obj)
		},

		addSubmittedQuestion(obj) {
			this.submittedQuestions.push(obj)
		},

		clearAccountData() {
			Object.keys(this.accountData).forEach(key => this.accountData[key] = undefined)
		},

		getTempData() {
			return JSON.parse(localStorage.getItem('tita-temp'))
		},

		setAccountData(obj) {
			this.accountData = obj
		},

		setAccountProperty(key, value) {
			this.accountData[key] = value
		},

		setTempData(data) {
			localStorage.setItem('tita-temp', JSON.stringify(data))
		},

		setToken(token) {
			this.token = token
		},

		onUserEventsSubmitted(data) {
			// if (data.code == null) {
				this.userEvents = []
			// }
		},

		// submitArticleRead() {
		// 	fetch(process.env.VUE_APP_URL_API + 'api/data/logArticleRead', {
		// 		method: 'POST',
		// 		headers: new Headers({
		// 			'Authorization': 'Basic '+btoa('tita_user:ef4515ee-5e13-423d-89f1-a2f4730ad346'), 
		// 			"Content-Type": "application/json",
		// 		}),
		// 		body: JSON.stringify(this.userEvents)
		// 	})
		// 		.then(response => response.json())
		// 		.then(data => this.onUserEventsSubmitted())
		// 		.catch(error => console.error('Error:', error))
		// },

		// submitVideoEvents() {
		// 	fetch(process.env.VUE_APP_URL_API + 'api/data/logVideoActivity', {
		// 		method: 'POST',
		// 		headers: new Headers({
		// 			'Authorization': 'Basic '+btoa('tita_user:ef4515ee-5e13-423d-89f1-a2f4730ad346'), 
		// 			"Content-Type": "application/json",
		// 		}),
		// 		body: JSON.stringify(this.userEvents)
		// 	})
		// 		.then(response => response.json())
		// 		.then(data => this.onUserEventsSubmitted())
		// 		.catch(error => console.error('Error:', error))
		// },

		submitUserEvents() {
			fetch(process.env.VUE_APP_URL_API + 'api/logActivity/add', {
				method: 'POST',
				headers: new Headers({
					'Authorization': 'Basic '+btoa('tita_user:ef4515ee-5e13-423d-89f1-a2f4730ad346'), 
					"Content-Type": "application/json",
				}),
				body: JSON.stringify(this.userEvents)
			})
				.then(response => response.json())
				.then(data => this.onUserEventsSubmitted())
				.catch(error => console.error('Error:', error))
		}
	},
})