<template>
  <div class="view ac-step">
		<h2>¿Cuál es tu nombre?</h2>
		<p>Elegí el nombre o apodo que más te guste. Asegurate de que no incluya información personal, como tu apellido.</p>
		<div class="form">
			<div class="form-field-vertical">
				<input type="text"
					v-model="displayName"
					placeholder="Usuaria"
					@keyup.enter="nextStep"
					:valid="isValid"
					:invalid="isInvalid"
				/>
				<span class="hint">Máximo 14 caracteres.</span>
			</div>
		</div>
		<div class="btn-container">
			<button
				type="button"
				class="btn btn-primary btn-lg"
				:disabled="!isValid"
				@click="nextStep"
				>
					{{ msg.common.next }}
			</button>
		</div>
  </div>
</template>

<script>
import messages from '@/lang/es.js'
import { useAppStore } from '@/stores';
export default {
	name: 'ACName',
  data() {
    return {
			displayName: '',
    }
  },

	computed: {
		isNotEmpty() {
			return this.displayName.length > 0
		},

		isValid() {
			if (this.isNotEmpty && this.displayName.length <= 14) {
				return true
			}

			else {
				return null
			}
		},

		isInvalid() {
			if (this.isNotEmpty && this.displayName.length > 14) {
				return true
			}

			else {
				return null
			}
		}
	},

	methods: {
    nextStep() {
      this.$router.push({name: 'FechaTest'});
			this.$emit('submit', 'name', this.displayName)
      this.$emit('step-change', 3);
    }
  },

	created() {
		this.msg = messages
	},

  mounted() {
    this.$emit('step-change', 2)
		if (useAppStore().accountData.nickname?.length > 0) {
			this.displayName = useAppStore().accountData.nickname
		}
  },
};
</script>