import { createRouter, createWebHashHistory } from 'vue-router'
import { useAppStore } from '@/stores/index.js'
import userEventLogger from '@/eventLogger.js';

import AccountCreationView from '../views/accountcreation/AccountCreationView.vue'
import ACCuid from '@/components/accountcreation/ACCuid.vue';
import ACStart from '../components/accountcreation/ACStart.vue'
import ACName from '../components/accountcreation/ACName.vue'
import ACTestDate from '../components/accountcreation/ACTestDate.vue'
import ACEmail from '@/components/accountcreation/ACEmail.vue';
import ACPin from '@/components/accountcreation/ACPin.vue';

import OnboardingView from '../views/onboarding/OnboardingView.vue'
import LoginView from '@/views/LoginView.vue';
import RecoverPasswordView from '@/views/RecoverPasswordView.vue'

import CalendarView from '../views/appmain/CalendarView.vue'
import NotificationsView from '../views/appmain/NotificationsView.vue'

import MainAppView from '../views/appmain/MainAppView.vue'
import HomeView from '../views/appmain/HomeView.vue'
import QuestionsView from '../views/appmain/QuestionsView.vue'
import InformationView from '../views/appmain/InformationView.vue'
import ArticleView from '../views/appmain/ArticleView.vue'
import PodcastsView from '../views/appmain/PodcastsView.vue'
import StoriesView from '../views/appmain/StoriesView.vue'
import SubmitQuestionView from '../views/appmain/SubmitQuestionView.vue'
import SubmittedQuestionsView from '../views/appmain/SubmittedQuestionsView.vue'
import TestsView from '../views/appmain/TestsView.vue'

const routes = [
	{
		path: '/app',
		name: 'app',
		component: MainAppView,
		beforeEnter(from, to) {
			if (useAppStore().accountData.token && !useAppStore().isLoggedIn) {
				return {
					name: 'Login',
					query: {
						redirectedFrom: from.path
					}
				}
			}

			else if (!useAppStore().accountData.token) {

				return {
					name: 'CUID'
				}
			}
		},
		children: [
			{
				path: 'home',
				name: 'home',
				component: HomeView,
			},
			{
				path: 'agenda',
				name: 'Agenda',
				component: CalendarView
			},
			{
				path: 'agenda',
				name: 'Agenda',
				component: CalendarView
			},
			{
				path: 'notificaciones',
				name: 'Notificaciones',
				component: NotificationsView
			},
			{
				path: 'informacion',
				name: 'Información',
				// component: InformationView,
				children: [
					{
						path: 'articulo/:id',
						name: 'ArticleView',
						component: ArticleView
					},
					{
						path: 'podcasts',
						name: 'Podcasts',
						component: PodcastsView
					},
					{
						path: 'preguntas',
						name: 'Preguntas y respuestas',
						component: QuestionsView
					},
					{
						path: 'preguntas/enviadas',
						name: 'Preguntas enviadas',
						component: SubmittedQuestionsView
					},
					{
						path: 'preguntas/enviar',
						name: 'Enviar pregunta',
						component: SubmitQuestionView
					},
					{
						path: 'testimonios',
						name: 'Testimonios',
						component: StoriesView
					},
					{
						path: 'testycontroles',
						name: 'Test y controles',
						component: TestsView
					},
				]
			}
		]
	},
	{
		path: '/crearcuenta',
		name: 'crearcuenta',
		component: AccountCreationView,
		beforeEnter(from, to) {
			if (useAppStore().accountData.token) {
				return {
					name: 'Login',
					query: { redirectFrom: to.fullPath }
				}
			}
		},
		children: [
			{
				path: '',
				name: 'CUID',
				component: ACCuid
			},
			{
				path: 'onboarding',
				name: 'onboarding',
				component: OnboardingView
			},
			{
				path: 'inicio',
				name: 'Inicio',
				component: ACStart
			},
			{
				path: 'nombre',
				name: 'Nombre',
				component: ACName
			},
			{
				path: 'fecha',
				name: 'FechaTest',
				component: ACTestDate
			},
			{
				path: 'email',
				name: 'Email',
				component: ACEmail
			},
			{
				path: 'pin',
				name: 'PIN',
				component: ACPin,
			}
		]
	},
	{
		path: '/login',
		name: 'Login',
		component: LoginView,
		beforeEnter() {
			if (useAppStore().isLoggedIn) {
				return {
					name: 'home'
				}
			}
		}
	},
	{
		path: '/recoverpassword',
		name: 'RecoverPassword',
		component: RecoverPasswordView
	}
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

router.beforeEach((to, from) => {
	if (!useAppStore().accountData.token && !to.path.includes('crearcuenta')) {
		return {
			name: 'CUID',
			query: { redirectFrom: to.fullPath }
		}
	}

	if (to.path == '/') {
		return {
			name: 'home'
		}
	}
	
})

 router.afterEach((to, from) => {
	if (['Login', 'home', 'onboarding', 'CUID'].includes(to.name)) {
		useAppStore().showNavBack = false
	}

	else {
		if (to.path !== '/crearcuenta/' && to.path !== '/crearcuenta') {
			useAppStore().showNavBack = true
		}
	}
 })

export default router
