<template>
	<div
		class="view article"
		:class="getClassName(article.articleTypeCode)"
		v-if="article"
		ref="article"
	>
		<div class="article-hero">
			<div class="header">
				<h1>{{ article.title }}</h1>
			</div>
		</div>
		<div class="subtitle">
			<p>{{ article.subtitle }}</p>
		</div>
		<div
			class="article-content"
			v-html="article.content"
		></div>
		<div
			v-if="article.articleTypeCode == 'STO' && article.url"
			id="videoPlayer"
		></div>
		<div
			v-if="article.articleTypeCode == 'POD' && article.url"
		>
			<audio :src="url"></audio>
		</div>
		<div class="feedback-box">
			<p>¿Te fue útil esta información?</p>
			<div class="btn-container">
				<label for="feedback-positive" id="feedback-positive-label" aria-label="Sí">
					<input type="radio" name="feedback" id="feedback-positive" aria-labelledby="feedback-positive-label">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M7 10V22" stroke="#15663D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M15 5.88L14 10H19.83C20.1405 10 20.4467 10.0723 20.7244 10.2111C21.0021 10.35 21.2437 10.5516 21.43 10.8C21.6163 11.0484 21.7422 11.3367 21.7977 11.6422C21.8533 11.9477 21.8369 12.2619 21.75 12.56L19.42 20.56C19.2988 20.9754 19.0462 21.3404 18.7 21.6C18.3538 21.8596 17.9327 22 17.5 22H4C3.46957 22 2.96086 21.7893 2.58579 21.4142C2.21071 21.0391 2 20.5304 2 20V12C2 11.4696 2.21071 10.9609 2.58579 10.5858C2.96086 10.2107 3.46957 10 4 10H6.76C7.13208 9.9998 7.49674 9.89581 7.81296 9.69972C8.12917 9.50363 8.38442 9.22321 8.55 8.89L12 2C12.4716 2.00584 12.9357 2.11817 13.3578 2.3286C13.7799 2.53902 14.1489 2.84211 14.4374 3.2152C14.7259 3.5883 14.9263 4.02176 15.0237 4.4832C15.1212 4.94464 15.113 5.42213 15 5.88Z" stroke="#15663D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>																	
				</label>
				<label for="feedback-negative" id="feedback-negative-label" aria-label="No">
					<input type="radio" name="feedback" id="feedback-negative" aria-labelledby="feedback-negative-label">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M17 14V2" stroke="#15663D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M9 18.12L10 14H4.17C3.85951 14 3.55328 13.9277 3.27557 13.7889C2.99786 13.65 2.75629 13.4484 2.57 13.2C2.3837 12.9516 2.2578 12.6633 2.20226 12.3578C2.14672 12.0523 2.16306 11.7381 2.25 11.44L4.58 3.44C4.70117 3.02457 4.95381 2.65964 5.3 2.4C5.64619 2.14036 6.06726 2 6.5 2H20C20.5304 2 21.0391 2.21071 21.4142 2.58579C21.7893 2.96086 22 3.46957 22 4V12C22 12.5304 21.7893 13.0391 21.4142 13.4142C21.0391 13.7893 20.5304 14 20 14H17.24C16.8679 14.0002 16.5033 14.1042 16.187 14.3003C15.8708 14.4964 15.6156 14.7768 15.45 15.11L12 22C11.5284 21.9942 11.0643 21.8818 10.6422 21.6714C10.2201 21.461 9.85107 21.1579 9.56259 20.7848C9.27412 20.4117 9.07368 19.9782 8.97626 19.5168C8.87884 19.0554 8.88696 18.5779 9 18.12Z" stroke="#15663D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
				</label>
			</div>
		</div>
		<div class="related-content" v-if="article.relatedArticles">
			<p class="title">También te puede interesar</p>
			<div
				v-for="relatedArticle in article.relatedArticles"
				:key="relatedArticle.articleId"
				class="related-item"
			>
				<p>{{ relatedArticle.title }}</p>
				<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M6 12L10 8L6 4" stroke="#474747" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
				</svg>								
			</div>
		</div>
	</div>
</template>

<script>
import { useAppStore } from '@/stores/index.js'
import userEventLogger from '@/eventLogger.js';
export default {
	name: 'ArticleView',
	data() {
		return {
			article: null,
			videoEventsLogged: false
		}
	},

	computed: {
	},

	methods: {
		getClassName(code) {
			switch (code) {
				case 'TES':
					return 'exams'
				case 'PRE':
					return 'question'
				default: return ''
			}
		},
		
		onPlayerStateChange(event) {
			if ([0, 1, 2].includes(event.data)) {
				userEventLogger.logVideoAction(event, this.article.articleId)
			}
		},
	},

	created() {
		fetch(process.env.VUE_APP_URL_API + `api/article/read`, {
			method: 'POST',
			headers: new Headers({
				'Authorization': 'Basic '+btoa('tita_user:ef4515ee-5e13-423d-89f1-a2f4730ad346'), 
				"Content-Type": "application/json",
			}),
			body: JSON.stringify({
				articleId: this.$route.params.id,
				user: useAppStore().accountData.userId,
				token: useAppStore().accountData.token,
				ip: "74.207.237.175",
				online: "true",
				source: "APP"
			})
		})
			.then(response => response.json())
			.then(data => this.article = data)
			.catch(error => console.error('Error:', error));
	},

	updated() {
		if (this.article.articleTypeCode == 'STO' && this.article.url) {
			const tag = document.createElement('script');
			tag.src = 'https://www.youtube.com/iframe_api';
			const firstScriptTag = document.getElementsByTagName('script')[0];
			firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

			window.onYouTubeIframeAPIReady = () => {
				this.player = new window.YT.Player('videoPlayer', {
					height: '360',
					width: '640',
					videoId: this.article.url,
					events: {
						'onReady': this.onPlayerReady,
						'onStateChange': this.onPlayerStateChange
					}
				});
			};
		}
	},
	beforeRouteLeave() {
		var article = this.$refs.article
		var scrolledToBottom = article.scrollHeight - Math.round(article.scrollTop) <= article.clientHeight
		userEventLogger.logArticleLeave(this.article.articleId, scrolledToBottom, new Date().toISOString())

		if (useAppStore().userEvents.length > 0) {
			// console.log(useAppStore().userEvents)
			useAppStore().submitUserEvents()
		}
  },
}
</script>

<style scoped>
.view.article {
	overflow-y: auto;
}
</style>